export const stats = [
  {
    label: "Years of Experience",
    value: "2",
  },
  {
    label: "Projects Completed",
    value: "50",
  },
  {
    label: "Clients",
    value: "100",
  }
];
