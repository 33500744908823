import Box from "@mui/joy/Box";

export default function App() {
    return <>
        <Box
            sx={{
                paddingX: {
                    xs: "2%", sm: "10%",
                }
            }}>

        </Box>
    </>;
}
