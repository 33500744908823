export const navigations = [{
    path: "#", label: "Home"
}, {
    path: "#service", label: "Service"
}, {
    path: "#apps", label: "Apps"
}, {
    path: "#resume", label: "Resume"
}, {
    path: "#contact", label: "Contact"
}];